import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Container, Col, Row } from "react-awesome-styled-grid";
import DOMPurify from "dompurify"; // Import DOMPurify for sanitization
import Settings from "src/stores/Settings";

import { colors, mediaquery, spacing } from "src/styles/variables";
import Checkbox from "src/atoms/Checkbox";
import Input from "src/atoms/Input"; // Import the new Input component
import HeaderCurved from "src/atoms/HeaderCurved/HeaderCurved";
import RichText from "src/atoms/RichText";

const TrackerForm = ({
  formSlideText,
  formConsent,
  onFormValidityChange,
  formIsDirty,
  currentlyShown,
  apiError,
}) => {
  const {
    firstNameLabel,
    firstNamePlaceholder,
    firstNameError,
    firstNameErrorDetailed,
    lastNameLabel,
    lastNamePlaceholder,
    lastNameError,
    lastNameErrorDetailed,
    emailLabel,
    emailPlaceholder,
    emailError,
    emailErrorDetailed,
    phoneLabel,
    phonePlaceholder,
    phoneError,
    phoneErrorDetailed,
    unknownApiError,
  } = useContext(Settings).translations;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    emailSubscriptionConsent: false,
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [dirty, setDirty] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    let trimmedValue = value.trim();
    if (id === "phoneNumber") {
      trimmedValue = value.replace(/[^\d+]/g, ""); // Remove all characters except digits and +
    } else if (id === "email") {
      trimmedValue = value.toLowerCase().replace(/[^a-z0-9._%+-@]/g, ""); // Remove disallowed characters
    }
    const sanitizedValue = DOMPurify.sanitize(trimmedValue);
    if (dirty[id]) {
      validateField({ target: { id: id, value: sanitizedValue } });
    }
    setFormData({ ...formData, [id]: sanitizedValue });
  };

  const handleBlur = (e) => {
    const { id } = e.target;
    setDirty({ ...dirty, [id]: true });
    validateField(e);
  };

  const validateField = (e) => {
    const { id, value } = e.target;
    let errorMessage = "";

    switch (id) {
      case "firstName":
        if (!value) {
          errorMessage = firstNameError || "First Name is required.";
        } else if (!validateFirstName(value)) {
          errorMessage =
            firstNameErrorDetailed ||
            firstNameError ||
            "First Name can only contain letters, spaces, hyphens, and apostrophes.";
        } else {
          errorMessage = "";
        }
        break;
      case "lastName":
        if (!value) {
          errorMessage = lastNameError || "Last Name is required.";
        } else if (!validateLastName(value)) {
          errorMessage =
            lastNameErrorDetailed ||
            lastNameError ||
            "Last Name can only contain letters, spaces, hyphens, and apostrophes.";
        } else {
          errorMessage = "";
        }
        break;
      case "email":
        if (!value) {
          errorMessage = emailError || "Email is required.";
        } else if (!validateEmail(value)) {
          errorMessage =
            emailError ||
            emailErrorDetailed ||
            "Email must be a valid email address.";
        } else {
          errorMessage = "";
        }
        break;
      case "phoneNumber":
        if (!value) {
          errorMessage = phoneError || "Phone Number is required.";
        } else if (!validatePhoneNumber(value)) {
          errorMessage =
            phoneErrorDetailed ||
            phoneError ||
            "Phone Number must be a valid international phone number, e.g., +15612767156.";
        } else {
          errorMessage = "";
        }
        break;
      default:
        break;
    }

    setErrors({ ...errors, [id]: errorMessage });
  };

  const validateFirstName = (str) => {
    const re = /^[A-Za-zÀ-ÖØ-öø-ÿ''’` -]{1,100}$/;
    return re.test(String(str));
  };
  const validateLastName = (str) => {
    const re = /^[A-Za-zÀ-ÖØ-öø-ÿ''’` -]{2,100}$/;
    return re.test(String(str));
  };

  const validateEmail = (email) => {
    const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhoneNumber = (phoneNumber) => {
    const re = /^\+?[1-9]\d{6,14}$/;
    return re.test(String(phoneNumber));
  };

  const validateForm = () => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      emailSubscriptionConsent,
    } = formData;

    const isFormValid =
      validateFirstName(firstName) &&
      validateLastName(lastName) &&
      validateEmail(email) &&
      validatePhoneNumber(phoneNumber) &&
      emailSubscriptionConsent;

    const isFormDirty =
      firstName || lastName || email || phoneNumber || emailSubscriptionConsent;
    formIsDirty(!!isFormDirty);

    onFormValidityChange(isFormValid && formData);
    return isFormValid;
  };

  useEffect(() => {
    validateForm();
  }, [formData]);

  return (
    <>
      <HeaderCurvedStyled
        fixedHeight={false}
        backgroundColor={colors.activiaGreen}
      >
        <Container>
          <NoMarginRowStyled>
            <Col md={10} offset={{ md: 3 }}>
              {formSlideText && (
                <RichTextWrapper>
                  <RichText
                    as="div"
                    enableRenderer
                    headColor={colors.white}
                    bodyColor={colors.white}
                    doc={formSlideText.value}
                  />
                </RichTextWrapper>
              )}
            </Col>
          </NoMarginRowStyled>
        </Container>
      </HeaderCurvedStyled>
      <Container>
        <RowStyled as="section">
          <Col md={10} offset={{ md: 3 }}>
            <Form>
              <Input
                id="firstName"
                label={`${firstNameLabel ?? "First Name"}`}
                placeholder={`${firstNamePlaceholder ?? "First Name"}`}
                value={formData.firstName}
                onChange={handleInputChange}
                onBlur={handleBlur}
                required
                tabIndex={currentlyShown ? 0 : -1}
                error={errors.firstName}
              />
              <Input
                id="lastName"
                label={`${lastNameLabel ?? "Last Name"}`}
                placeholder={`${lastNamePlaceholder ?? "Last Name"}`}
                value={formData.lastName}
                onChange={handleInputChange}
                onBlur={handleBlur}
                required
                tabIndex={currentlyShown ? 0 : -1}
                error={errors.lastName}
              />
              <Input
                id="email"
                label={`${emailLabel ?? "Email"}`}
                placeholder={`${emailPlaceholder ?? "Email"}`}
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={handleBlur}
                required
                tabIndex={currentlyShown ? 0 : -1}
                error={errors.email}
              />
              <Input
                id="phoneNumber"
                label={`${phoneLabel ?? "Phone Number"}`}
                placeholder={`${phonePlaceholder ?? "Phone Number"}`}
                type="tel"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                onBlur={handleBlur}
                required
                tabIndex={currentlyShown ? 0 : -1}
                error={errors.phoneNumber}
              />
              <div style={{ marginTop: "0.5rem" }}>
                {/* Hide to disable tabbing to the slide from other slides */}
                <label htmlFor="terms" hidden={!currentlyShown}>
                  <Checkbox
                    id="terms"
                    label={
                      <RichText as="div" enableRenderer doc={formConsent} />
                    }
                    setIsChecked={(e) => {
                      setFormData({
                        ...formData,
                        emailSubscriptionConsent: e,
                      });
                    }}
                  />
                </label>
              </div>
              {apiError && (
                <p style={{ color: colors.red, marginTop: "0.5rem" }}>
                  {unknownApiError || "An unknown error occurred."}
                </p>
              )}
            </Form>
          </Col>
        </RowStyled>
      </Container>
    </>
  );
};

TrackerForm.propTypes = {
  formSlideText: PropTypes.object,
  formConsent: PropTypes.object,
  formPosition: PropTypes.string,
  optionalForm: PropTypes.bool,
  onFormValidityChange: PropTypes.func.isRequired,
  formIsDirty: PropTypes.func.isRequired,
  currentlyShown: PropTypes.bool,
  apiError: PropTypes.bool,
};

export default TrackerForm;

const HeaderCurvedStyled = styled(HeaderCurved)`
  margin-top: -100px;

  & > div {
    min-height: 0;
    padding-top: calc(2rem + 100px);
  }
`;

const RowStyled = styled(Row)`
  margin: ${spacing.stack.xl};
`;

const Form = styled.form`
  color: ${colors.black};
  margin-top: 2rem;
  padding-bottom: 70px;

  ${mediaquery.md(css`
    padding-left: 0;
    padding-right: 0;
  `)}
`;

const NoMarginRowStyled = styled(Row)``;

const RichTextWrapper = styled.div`
  padding-top: 0.5rem;

  * {
    color: ${colors.white}!important;
  }

  ${mediaquery.md(css`
    padding-top: 1rem;
  `)}
`;
