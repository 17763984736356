import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Link from "src/atoms/Link";
import { mediaquery, spacing } from "src/styles/variables";
import React, { useEffect, useState, useMemo, useCallback } from "react";

const Breadcrumb = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulSettings {
        hcpParentSlug
        showBreadcrumbs
      }

      allContentfulPages: allContentfulPage {
        nodes {
          pageTitle
          seo {
            title
          }
          slug
        }
      }
      allContentfulEditorialPhotographyWithCard {
        nodes {
          cardTitle
          seo {
            title
          }
          slug
        }
      }
      allContentfulEditorialIllustrationWithCard {
        nodes {
          cardTitle
          seo {
            title
          }
          slug
        }
      }
      allContentfulInfluencerWithCard {
        nodes {
          cardTitle
          seo {
            title
          }
          slug
        }
      }
      allContentfulRecipeWithCard {
        nodes {
          cardTitle
          seo {
            title
          }
          slug
        }
      }
      allContentfulProductFlavorWithCard {
        nodes {
          name
          slug
          seo {
            title
          }
        }
      }
      allContentfulProductCategoryList {
        nodes {
          title
          seo {
            title
          }
          slug
        }
      }
      allContentfulProductCategory {
        nodes {
          title
          slug
        }
      }
    }
  `);

  const [currentSlug, setCurrentSlug] = useState("");

  // Combines all possible page nodes from different content types into a single array
  const allNodes = useMemo(
    () => [
      ...data.allContentfulEditorialPhotographyWithCard.nodes,
      ...data.allContentfulEditorialIllustrationWithCard.nodes,
      ...data.allContentfulInfluencerWithCard.nodes,
      ...data.allContentfulRecipeWithCard.nodes,
      ...data.allContentfulProductFlavorWithCard.nodes,
      ...data.allContentfulPages.nodes,
      ...data.allContentfulProductCategoryList.nodes,
      ...data.allContentfulProductCategory.nodes,
    ],
    [data]
  );

  const combinedNodes = useMemo(
    () =>
      allNodes.map((node) => ({
        ...node,
      })),
    [allNodes]
  );

  /**
   * Creates breadcrumb items array based on current URL path
   * Handles both exact matches and partial matches for path segments
   */
  const createBreadcrumbItems = useCallback(
    (path) => {
      const cleanPath = path.replace(/\/$/, "");
      const isHcpPath = cleanPath.includes(
        data.contentfulSettings.hcpParentSlug
      );

      let items = [];
      if (!isHcpPath) {
        const homeNode = combinedNodes.find((node) => node.slug === "/");
        if (homeNode) {
          items.push(homeNode);
        }
      }

      if (cleanPath === "") {
        return items;
      }

      // Split path and create cumulative paths (e.g., /a/b/c -> ['/a', '/a/b', '/a/b/c'])
      const pathSegments = cleanPath.split("/").filter(Boolean);
      const breadcrumbPaths = pathSegments.reduce((acc, segment, index) => {
        if (index === 0) {
          acc.push(`/${segment}`);
        } else {
          acc.push(`${acc[index - 1]}/${segment}`);
        }
        return acc;
      }, []);

      // Find matching content nodes for each path segment
      const additionalItems = breadcrumbPaths
        .map((path) => {
          let nodeData = combinedNodes.find((node) => {
            const normalizedNodeSlug = `/${node.slug}`.replace(/\/+/g, "/");
            return normalizedNodeSlug === path;
          });

          // Fallback to matching just the last segment if exact match fails
          if (!nodeData) {
            const pathSegment = path.split("/").pop();
            nodeData = combinedNodes.find((node) => {
              const nodeSlugSegment = node.slug.split("/").pop();
              return nodeSlugSegment === pathSegment;
            });
          }

          return nodeData;
        })
        .filter(Boolean);

      return [...items, ...additionalItems].filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.slug === item.slug)
      );
    },
    [combinedNodes, data.contentfulSettings.hcpParentSlug]
  );

  useEffect(() => {
    setCurrentSlug(window.location.pathname);
  }, []);

  const breadcrumbItems = useMemo(() => createBreadcrumbItems(currentSlug), [
    currentSlug,
    createBreadcrumbItems,
  ]);

  if (
    !data.contentfulSettings.showBreadcrumbs ||
    breadcrumbItems?.length === 1
  ) {
    return null;
  }

  return (
    <BreadcrumbContainer aria-label="Breadcrumb">
      <ol>
        {breadcrumbItems.map((item, index) => {
          const isLast = index === breadcrumbItems.length - 1;
          const displayText =
            item.cardTitle ||
            item.title ||
            item.name ||
            item.pageTitle ||
            item.seo?.title;
          const hasSeo = !!item.seo;

          return (
            <li key={item.slug} aria-current={isLast ? "page" : null}>
              {isLast || !hasSeo ? (
                <span>{displayText}</span>
              ) : (
                <Link url={`/${item.slug}`.replace(/\/+/g, "/")}>
                  {displayText}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </BreadcrumbContainer>
  );
};

export const BreadcrumbContainer = styled.nav`
  padding-block: ${spacing.default.sm};

  ${mediaquery.md(css`
    padding-block: ${spacing.default.lg};
  `)}

  ol {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  li {
    display: flex;
    align-items: center;

    &:not(:last-child)::after {
      content: "/";
      margin-left: 0.5rem;
    }
  }
`;

export default React.memo(Breadcrumb);
