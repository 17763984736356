import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { SettingsStore, SettingsShape } from "src/stores/Settings";
import { PageStore } from "src/stores/Page";
import Layout from "src/layouts/Main";
import TrackerResults from "src/molecules/TrackerResults";
import TrackerCategories from "src/molecules/Tracker/TrackerCategories";
import trackEvent from "./trackEvent";

import { TYPES as PICTURE_TYPES } from "src/atoms/Picture";
import getImage from "src/utils/getImage";

const TrackerResultsPage = ({
  data,
  pageContext: { locale, localizedSlugs },
  path,
}) => {
  if (!data) {
    throw new Error(`Page query failed: reason unknown`);
  }

  const settings = {
    ...data.contentfulSettings,
    locale,
    localizedSlugs,
  };
  const trackerResult = data.contentfulTracker;
  const {
    id,
    categories,
    resultPageText,
    resultPageImage,
    shareModule,
  } = trackerResult;

  const trackerTitle = data.contentfulPage.pageTitle;
  const trackerParentSlug = data.contentfulPage.slug;
  const trackerResultPageTitle = trackerResult.trackerResultPageTitle;

  useEffect(() => {
    window.tc_vars = {
      country: locale.currentRegion.region,
      environment: process.env.GATSBY_TRACKING_ENV,
      page_category: "Tracker Result",
      page_name: "Tracker Result Page",
      page_type: "Tracker Result Page",
      page_subcategory: "",
      page: path,
      work_env: process.env.GATSBY_TRACKING_ENV,
      brand: "Activia",
    };
    window.tC?.container?.reload({ events: { page_view: [{}, {}] } });

    trackEvent();
  }, []);

  return (
    <SettingsStore value={settings}>
      <PageStore>
        <Layout header locale={locale}>
          <TrackerResults
            id={id}
            title={resultPageText}
            categories={categories}
            trackerTitle={trackerTitle}
            trackerParentSlug={trackerParentSlug}
            trackerResultPageTitle={trackerResultPageTitle}
            imageDesktop={getImage(resultPageImage.large, PICTURE_TYPES.fluid)}
            imageMobile={getImage(resultPageImage.small, PICTURE_TYPES.fluid)}
            shareModuleTwitterText={shareModule?.twitterText}
            shareModuleTitle={shareModule?.title}
          />
        </Layout>
      </PageStore>
    </SettingsStore>
  );
};

TrackerResultsPage.propTypes = {
  data: PropTypes.shape({
    contentfulSettings: PropTypes.shape(SettingsShape).isRequired,
    contentfulPage: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      pageTitle: PropTypes.string.isRequired,
    }),
    contentfulTracker: PropTypes.shape({
      //seo: PropTypes.object.isRequired,
      //hint: PropTypes.string,
      id: PropTypes.string,
      title: PropTypes.string,
      trackerResultPageTitle: PropTypes.string,
      slug: PropTypes.string,
      categories: PropTypes.arrayOf(TrackerCategories).isRequired,
      resultPageText: PropTypes.array.isRequired,
      resultPageImage: PropTypes.object.isRequired,
      shareModule: PropTypes.object,
    }),
  }),
  pageContext: PropTypes.shape({
    locale: PropTypes.object.isRequired,
    localizedSlugs: PropTypes.instanceOf(Array),
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export const pageQuery = graphql`
  query getTracker($nodeLocale: String!, $id: String!, $tag: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...SettingsFields
    }

    contentfulPage {
      slug
      pageTitle
    }

    contentfulTracker(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      ...Tracker
    }
  }
`;

export default TrackerResultsPage;
