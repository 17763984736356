import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors, rem, lineHeight, fontWeights } from "src/styles/variables";

const Input = React.memo(
  React.forwardRef(
    ({ id, label, value, onChange, error, children, ...rest }, ref) => {
      return (
        <Outer>
          <StyledLabel htmlFor={id}>{label || children}</StyledLabel>
          <StyledInput
            id={id}
            name={id}
            value={value}
            onChange={onChange}
            ref={ref}
            {...rest}
          />
          {error && <StyledError>{error}</StyledError>}
        </Outer>
      );
    }
  )
);

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  children: PropTypes.node,
};

Input.defaultProps = {
  label: "",
  error: "",
  children: undefined,
};

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const StyledInput = styled.input`
  appearance: none;
  border: 1px solid ${colors.mediumGreen};
  border-radius: 6px;
  padding: 0.5rem;
  font-size: ${rem(16)};
  line-height: ${lineHeight(16, 30)};
  font-weight: ${fontWeights.book};
  color: ${colors.black};
  margin-top: 0.5rem;

  &:focus {
    border-color: ${colors.activiaGreen};
    outline: none;
  }
`;

const StyledLabel = styled.label`
  color: ${colors.mediumGreen};
  font-size: ${rem(16)};
  line-height: ${lineHeight(16, 30)};
  font-weight: ${fontWeights.book};
`;

const StyledError = styled.span`
  color: ${colors.red};
  font-size: ${rem(14)};
  line-height: ${lineHeight(14, 20)};
  margin-top: 0.25rem;
`;

export default Input;
