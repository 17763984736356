import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "src/styles/variables";
import TrackerCategories from "src/molecules/Tracker/TrackerCategories";

const ProgressBarTracker = ({
  categories,
  trackerProgressItem,
  activeItemIndex,
  isFormInEnd,
}) => {
  const [progressItems, setProgressItems] = useState([]);

  const GetProgressItem = (id) => {
    return progressItems.length > 0
      ? progressItems.find(
          (item, index) =>
            index <= activeItemIndex &&
            item.id === id &&
            (item.type === "category" || item.type === "form")
        )
      : null;
  };

  useEffect(() => {
    if (!progressItems[activeItemIndex]) {
      setProgressItems((prevState) => [
        ...prevState,
        { ...trackerProgressItem },
      ]);
    }
  }, [progressItems, activeItemIndex, trackerProgressItem]);

  return (
    <Outer>
      <ProgressContainer>
        <Progress>
          {parseInt(Math.round(trackerProgressItem.progress), 10)}%
        </Progress>
        <ProgressBar>
          <ProgressFill progress={trackerProgressItem.progress} />
          <SegmentStart
            isFill={GetProgressItem("tracker-start")}
            className={GetProgressItem("tracker-start") ? "full" : null}
          />
          {categories?.map((category) => {
            return (
              <SegmentStart
                key={category.id}
                isFill={GetProgressItem(category.id)}
                className={GetProgressItem(category.id) ? "full" : null}
              />
            );
          })}
          {isFormInEnd && (
            <SegmentStart
              isFill={GetProgressItem("tracker-form")}
              className={GetProgressItem("tracker-form") ? "full" : null}
            />
          )}
          <SegmentStart
            isFill={GetProgressItem("tracker-thanks")}
            className={GetProgressItem("tracker-thanks") ? "full" : null}
          />
        </ProgressBar>
      </ProgressContainer>
      {/* <Inner progress={Math.min(100, Math.max(0, progress * 100))} /> */}
    </Outer>
  );
};

ProgressBarTracker.propTypes = {
  trackerProgressItem: PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(TrackerCategories).isRequired,
  activeItemIndex: PropTypes.number,
  isFormInEnd: PropTypes.bool,
};

const Progress = styled.div`
  padding-right: 20px;
  flex: 0 0 auto;
  font-weight: bold;
  color: ${colors.white};
`;
const ProgressBar = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  position: relative;

  &:before {
    content: "";
    display: block;
    background: ${colors.activiaMediumGreen};
    height: 3px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 0;
  }
`;

const SegmentStart = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: ${colors.activiaMediumGreen};
  position: relative;
  z-index: 2;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    background: ${colors.white};
    height: 20px;
    width: ${({ isFill }) => (isFill ? "100%" : "0%")};
    transition: width 0.1s cubic-bezier(0.58, 0, 0.56, 1);
  }

  &.full {
    &:before {
      transition: width 0.3s cubic-bezier(0.58, 0, 0.56, 1) 0.3s;
    }
  }
`;

const ProgressFill = styled.div`
  height: 3px;
  width: ${({ progress }) => `${progress}%`};
  background: ${colors.white};
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
  transition: width 0.3s cubic-bezier(0.58, 0, 0.56, 1);
`;

const ProgressContainer = styled.div`
  display: flex;
`;

const Outer = styled.div``;

export default ProgressBarTracker;
