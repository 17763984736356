import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { graphql, navigate } from "gatsby";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { SettingsStore, SettingsShape } from "src/stores/Settings";
import { PageStore } from "src/stores/Page";
import { LatestCardsStore } from "src/stores/LatestCards";
import Layout from "src/layouts/Main";
import SEO from "src/atoms/SEO";
import PageComponentList from "src/organisms/PageComponentList";
import mergeSansNullOrUndefined from "src/utils/mergeSansNullOrUndefined";
import ScreenReaderTitle from "src/atoms/ScreenReaderTitle";
import breadcrumb from "src/utils/breadcrumb";
import getMetaImageFields from "src/utils/getMetaImageFields";
import { TYPES as PICTURE_TYPES } from "src/atoms/Picture";
import getImage from "src/utils/getImage";
import trackEvent from "./trackEvent";
import mappers from "src/organisms/PageComponentList/mappers";
import { getRecipeJSON } from "src/utils/getRecipeMeta";

const ContentPage = ({
  data,
  pageContext: { locale, localizedSlugs, hreflang },
  path,
  location,
}) => {
  if (!data) {
    throw new Error(`Page query failed: reason unknown`);
  }

  const settings = {
    ...data.contentfulSettings,
    locale,
    localizedSlugs,
  };

  const contentKeys = [
    "contentfulInfluencerWithCard",
    "contentfulEditorialPhotographyWithCard",
    "contentfulEditorialIllustrationWithCard",
    "contentfulRecipeWithCard",
  ];
  const contentType = contentKeys.find((key) => data[key]);
  const page = data[contentType];
  const { createdAt, updatedAt } = page;

  const hero =
    (page &&
      page.components.find((item) => item.__typename === "ContentfulHero")) ||
    {};
  const hasHero = hero.__typename && hero.__typename === "ContentfulHero";
  const heroHasTitle = hasHero && hero.title;

  const containsFAQ =
    page &&
    page.components.length !== 0 &&
    page.components.some(
      (item) => item.__typename === "ContentfulQuestionAnswerList"
    );

  const containsRecipe =
    page &&
    page.components.length !== 0 &&
    page.components.some(
      (item) => item.__typename === "ContentfulRecipeDetails"
    );

  const fullUrl = `${locale.currentRegion.url}${path}`;

  const jsonld = [
    breadcrumb([{ name: page.seo.title || settings.seo.title, url: fullUrl }]),
  ];

  jsonld.push({
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": fullUrl,
    },
    headline: (hasHero && hero.title) || page.seo.title || settings.seo.title,
    image: hasHero
      ? getImage(hero.media.small, PICTURE_TYPES.fluid).src
      : getImage(settings.headerLogo, PICTURE_TYPES.fixed).src,
    datePublished: createdAt,
    dateModified: updatedAt,
    author: {
      "@type": "Organization",
      name: "Activia",
    },
    publisher: {
      "@type": "Organization",
      name: "Activia",
      logo: {
        "@type": "ImageObject",
        url: getImage(settings.headerLogo, PICTURE_TYPES.fixed).src,
      },
    },
    description: page.seo.description || settings.seo.description,
  });

  if (containsFAQ) {
    jsonld.push({
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: mappers["ContentfulQuestionAnswerList"]
        .mapper(
          page.components.find(
            (item) => item.__typename === "ContentfulQuestionAnswerList"
          )
        )
        .items.map(({ question, answer }) => ({
          "@type": "Question",
          name: question,
          acceptedAnswer: {
            "@type": "Answer",
            text: documentToPlainTextString(JSON.parse(answer.raw)),
          },
        })),
    });
  }

  if (containsRecipe) {
    console.log("containsRecipe: ", containsRecipe);
    jsonld.push(
      getRecipeJSON(
        mappers["ContentfulRecipeDetails"].mapper(
          page.components.find(
            (item) => item.__typename === "ContentfulRecipeDetails"
          )
        ),
        hasHero && getImage(hero.media.small, PICTURE_TYPES.fluid).src,
        getImage(settings.headerLogo, PICTURE_TYPES.fixed).src
      )
    );
  }

  const pageMetadata = {
    ...page.seo,
    image:
      page.seo.image && getMetaImageFields(page.seo.image, PICTURE_TYPES.fixed),
  };

  const meta = mergeSansNullOrUndefined(
    {
      image:
        hasHero && getMetaImageFields(hero.media.large, PICTURE_TYPES.fluid),
    },
    pageMetadata
  );

  const goToPrevPage = (e) => {
    e.preventDefault();

    const { navigationHistory } = location.state;
    navigationHistory.splice(-1, 1); // remove current page from history

    if (!navigationHistory.length) {
      navigate("/", { state: { navigationHistory: ["/"] } });
    } else {
      const to = navigationHistory[navigationHistory.length - 1];
      navigate(to, {
        state: {
          navigationHistory,
        },
      });
    }

    // https://github.com/gatsbyjs/gatsby/issues/5987
    // TODO: Check if we can leverage Gatsby's navigation
    //window.history.back();
  };

  useEffect(() => {
    const pageCategories = {
      contentfulInfluencerWithCard: "Influencer",
      contentfulEditorialPhotographyWithCard: "Editorial Photography",
      contentfulEditorialIllustrationWithCard: "Editorial Illustration",
      contentfulRecipeWithCard: "Recipe",
    };

    window.tc_vars = {
      country: locale.currentRegion.region,
      environment: process.env.GATSBY_TRACKING_ENV,
      page_category: pageCategories[contentType],
      page_name: page.seo.title,
      page_type: "Content Page",
      page_subcategory: page.category.name,
      page: path,
      work_env: process.env.GATSBY_TRACKING_ENV,
      brand: "Activia",
    };
    window.tC?.container?.reload({ events: { page_view: [{}, {}] } });

    trackEvent();
    //console.log('window.tc_vars content page',window.tc_vars);
  }, []);

  return (
    <SettingsStore value={settings}>
      <SEO
        path={path}
        meta={meta}
        jsonld={jsonld}
        hreflang={hreflang}
        localizedSlugs={localizedSlugs}
      />
      <LatestCardsStore {...data}>
        <PageStore initOverride={{ transparentHeader: false }}>
          <Layout locale={locale}>
            {(!hasHero || !heroHasTitle) && (
              <ScreenReaderTitle>{meta.title}</ScreenReaderTitle>
            )}

            <PageComponentList
              modules={page.components}
              onClose={goToPrevPage}
              pageType={contentType}
            />
          </Layout>
        </PageStore>
      </LatestCardsStore>
    </SettingsStore>
  );
};

const pageShape = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      seo: PropTypes.object.isRequired,
      componentsCollection: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired
  ),
};

ContentPage.propTypes = {
  data: PropTypes.shape({
    contentfulSettings: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape(SettingsShape)),
    }).isRequired,
    contentfulInfluencerWithCard: PropTypes.shape(pageShape),
    contentfulEditorialPhotographyWithCard: PropTypes.shape(pageShape),
    contentfulEditorialIllustrationWithCard: PropTypes.shape(pageShape),
    contentfulRecipeWithCard: PropTypes.shape(pageShape),
    allContentfulEditorialIllustrationWithCard: PropTypes.object,
    allContentfulEditorialPhotographyWithCard: PropTypes.object,
    allContentfulInfluencerWithCard: PropTypes.object,
    allContentfulPages: PropTypes.object,
    allContentfulProductCategory: PropTypes.object,
    allContentfulProductCategoryList: PropTypes.object,
    allContentfulProductFlavorWithCard: PropTypes.object,
    allContentfulRecipeWithCard: PropTypes.object,
  }),
  pageContext: PropTypes.shape({
    locale: PropTypes.object.isRequired,
    localizedSlugs: PropTypes.instanceOf(Array),
    hreflang: PropTypes.object,
  }).isRequired,
  path: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query getContentPage($nodeLocale: String!, $id: String!, $tag: String!) {
    # Settings
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...SettingsFields
    }

    contentfulInfluencerWithCard(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      ...Influencer
    }

    # Content pages
    contentfulEditorialPhotographyWithCard(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      ...EditorialPhotography
    }

    contentfulEditorialIllustrationWithCard(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      ...EditorialIllustration
    }

    contentfulRecipeWithCard(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      ...Recipe
    }

    # Latest cards
    latestEditorialPhotography: allContentfulEditorialPhotographyWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...EditorialPhotographyCard
        updatedAt
      }
    }

    latestEditorialIllustration: allContentfulEditorialIllustrationWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...EditorialIllustrationCard
        updatedAt
      }
    }

    latestInfluencer: allContentfulInfluencerWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...InfluencerCard
        updatedAt
      }
    }

    latestRecipe: allContentfulRecipeWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...RecipeCard
        updatedAt
      }
    }

    latestFlavor: allContentfulProductFlavorWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...FlavorCard
        updatedAt
      }
    }

    allContentfulPages: allContentfulPage(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      nodes {
        seo {
          title
        }
        slug
      }
    }

    allContentfulEditorialPhotographyWithCard(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      nodes {
        slug
      }
    }

    allContentfulEditorialIllustrationWithCard(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      nodes {
        seo {
          title
        }
        slug
      }
    }

    allContentfulInfluencerWithCard(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      nodes {
        seo {
          title
        }
        slug
      }
    }

    allContentfulRecipeWithCard(filter: { node_locale: { eq: $nodeLocale } }) {
      nodes {
        seo {
          title
        }
        slug
      }
    }

    allContentfulProductFlavorWithCard(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      nodes {
        name
        slug
      }
    }

    allContentfulProductCategoryList(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      nodes {
        seo {
          title
        }
        slug
      }
    }

    allContentfulProductCategory(filter: { node_locale: { eq: $nodeLocale } }) {
      nodes {
        title
        slug
      }
    }
  }
`;

export default ContentPage;
