import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";

import { spacing } from "src/styles/variables";
import CodeEmbed from "./CodeEmbed";
import Breadcrumb from "../Breadcrumb";

const PageComponent = (props) =>
  props.fullWidth ? (
    <CodeEmbed {...props} />
  ) : (
    <Container>
      <Breadcrumb />
      <RowStyled>
        <Col md={6} offset={{ md: 5 }}>
          <CodeEmbed {...props} />
        </Col>
      </RowStyled>
    </Container>
  );

const RowStyled = styled(Row)`
  margin: ${spacing.stack.xl};
`;

PageComponent.propTypes = {
  fullWidth: PropTypes.bool,
};

export default PageComponent;
