import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import styled, { css } from "styled-components";

import Button from "src/atoms/Button";
import { HeaderS, LabelL, LabelM } from "src/atoms/Typography";
import {
  colors,
  mediaquery,
  rem,
  lineHeight,
  fontWeights,
  spacing,
} from "src/styles/variables";
import { Container, Col, Row } from "react-awesome-styled-grid";
import HeaderCurved from "src/atoms/HeaderCurved/HeaderCurved";
import Settings from "src/stores/Settings";
import transparentize from "polished/lib/color/transparentize";

const TrackerQuestions = ({
  category,
  title,
  questionDescription,
  questionAnswers,
  backgroundColor,
  setSelectedAnswer,
  className,
  categoryId,
  questionId,
  questionIndex,
  questionsCount,
  currentlyShown,
}) => {
  const [activeAnswer, setActiveAnswer] = useState(null);
  const internalTitle = title?.replace(/\s/g, "_").trim().toLowerCase();
  const { trackerQuestionCount } = useContext(Settings).translations;
  let trackerQuestionCountText = trackerQuestionCount.replace(
    "{0}",
    questionIndex
  );
  trackerQuestionCountText = trackerQuestionCountText.replace(
    "{1}",
    questionsCount
  );

  return (
    <QuestionWrapper className="question-wrapper">
      <HeaderCurvedStyled fixedHeight={false} backgroundColor={backgroundColor}>
        <Container>
          <RowStyled as="section">
            <Col md={10} offset={{ md: 3 }}>
              <QuestionCount>
                {category} | {trackerQuestionCountText}
              </QuestionCount>
              {title && <QuestionHeader as="h1">{title}</QuestionHeader>}
              {questionDescription && (
                <QuestionDescription>{questionDescription}</QuestionDescription>
              )}
            </Col>
          </RowStyled>
        </Container>
      </HeaderCurvedStyled>

      <Container>
        <RowStyled as="section">
          <Col md={10} offset={{ md: 3 }}>
            <QuestionsList>
              {questionAnswers &&
                questionAnswers.map((item, index) => (
                  <ListItem key={`${internalTitle}_${index}`}>
                    <ListButton
                      type="button"
                      onClick={() => {
                        setSelectedAnswer(
                          categoryId,
                          questionId,
                          index,
                          parseInt(item.split(":")[1], 10)
                        );
                        setActiveAnswer(index);
                      }}
                      className={`${className} ${
                        index === activeAnswer ? "active" : ""
                      }`}
                      tabIndex={currentlyShown ? 0 : -1}
                    >
                      {item.split(":")[0]}
                    </ListButton>
                  </ListItem>
                ))}
              <input
                type="hidden"
                name={`${category} | ${title}`}
                value={` ${questionAnswers[activeAnswer]
                  ?.split(":")[0]
                  .trim()} `}
              />
              <input
                type="hidden"
                name={`${category} | ${trackerQuestionCountText} answer weight`}
                value={questionAnswers[activeAnswer]?.split(":")[1].trim()}
              />
            </QuestionsList>
          </Col>
        </RowStyled>
      </Container>
    </QuestionWrapper>
  );
};

TrackerQuestions.propTypes = {
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  questionDescription: PropTypes.string.isRequired,
  questionAnswers: PropTypes.array.isRequired,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  answers: PropTypes.string,
  setSelectedAnswer: PropTypes.func,
  categoryId: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  questionIndex: PropTypes.number.isRequired,
  questionsCount: PropTypes.number.isRequired,
  currentlyShown: PropTypes.bool,
};

export default TrackerQuestions;

const RowStyled = styled(Row)`
  margin: ${spacing.stack.xl};
`;

const HeaderCurvedStyled = styled(HeaderCurved)`
  margin-top: -100px;

  & > div {
    min-height: 0;
    padding-top: calc(2rem + 100px);
  }
`;

const QuestionCount = styled(LabelM)`
  text-transform: uppercase;
  margin-top: 1.2rem;
  letter-spacing: 0.1rem;

  ${mediaquery.md(css`
    margin-top: 1.7rem;
  `)}
`;

const QuestionWrapper = styled.div``;

const QuestionsList = styled.ul`
  margin-top: 2rem;
  padding-bottom: 70px;

  ${mediaquery.md(css`
    padding-left: 0;
    padding-right: 0;
  `)}
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
`;

const ListButton = styled(Button)`
  background-color: ${colors.white};
  background: none;
  color: ${colors.black};
  box-shadow: 3px 10px 17px 0 ${colors.mediumGrey};
  justify-content: flex-start;
  font-size: ${rem(19)};
  line-height: ${lineHeight(19, 28)};
  font-weight: ${fontWeights.medium};
  width: 100%;
  text-wrap: pretty;
  transition: all 0.3s ease;

  &.active {
    background-color: ${colors.activiaGreen};
    color: ${colors.white};
    box-shadow: 3px 10px 17px 0 ${transparentize(0.7, colors.mediumGrey)};
  }

  span {
    line-height: unset;
    text-align: left;
  }
`;

const QuestionHeader = styled(HeaderS)`
  padding: 1rem 0;

  ${mediaquery.md(css`
    padding: 1.5rem 0;
  `)}
`;

const QuestionDescription = styled(LabelL)`
  font-weight: ${fontWeights.book};
`;
