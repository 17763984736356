import { css } from "styled-components";

const BASE_FONT_SIZE = 16;

export const colors = {
  white: "#fff",
  translucidWhite: "rgba(255,255,255,0.4)",
  translucidBlack: "rgba(0,0,0,0.10)",
  mediumShadow: "rgba(0,0,0,0.4)",
  activiaGradient: ["#008830", "#03c046"],
  activiaLightGradient: ["#dbe7df", "#ffffff"],
  lightShadowGradient: ["rgba(0,0,0,0.4)", "rgba(0,0,0,0)"],
  mediumShadowGradient: ["rgba(0,0,0,0.5)", "rgba(0,0,0,0)"],
  cardFadeGradient: ["#fff", "rgba(255,255,255,0)"],
  activiaGreenGradient: ["rgba(2, 186, 68, 1)", "rgba(2, 186, 68, 0)"],

  activiaGreen: "#02ba44", // brand colour
  activiaMediumGreen: "#008830",
  mediumGreen: "#1d5b34",
  darkGreen: "#043e19",
  accessibilityGreen: "#007336",

  lighterGrey: "#f0f0f0",
  lightGrey: "#dedede",
  mediumGrey: "#adadad",
  darkGrey: "#666666",
  darkerGrey: "#434343",

  black: "#333333",
  beige: "#f0ebde",
  yellow: "#ebd87d",
  pink: "#eb8a7d",

  blue: "#61a0f4", // a nice outline colour, needed here for rounded-corners (see Button)
  red: "#ff0000", // error colour

  // Social media colors
  twitter: "#000",
  facebook: "#3b5998",
  pinterest: "#E60023",
  whatsapp: "#25D366",
  youtube: "#c4302b",
  instagram: {
    color1: "#f09433",
    color2: "#e6683c",
    color3: "#dc2743",
    color4: "#cc2366",
    color5: "#bc1888",
  },

  // Nutritional fact traffic light colours
  trafficLightLow: "#7BC35F",
  trafficLightMed: "#FFD159",
  trafficLightHigh: "#F05828",
};

export const rem = (px, addUnit = true) => {
  const rem = px / BASE_FONT_SIZE;
  return addUnit ? `${rem}rem` : rem;
};

export const lineHeight = (fontSizeInPixes, lineHeightInPixels) => {
  return lineHeightInPixels / fontSizeInPixes;
};

export const spacingValues = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 40,
};

export const spacing = {
  default: {
    xs: rem(spacingValues.xs),
    sm: rem(spacingValues.sm),
    md: rem(spacingValues.md),
    lg: rem(spacingValues.lg),
    xl: rem(spacingValues.xl),
  },
  inset: {
    xs: `${rem(spacingValues.xs)} ${rem(spacingValues.xs)}`,
    sm: `${rem(spacingValues.sm)} ${rem(spacingValues.sm)}`,
    md: `${rem(spacingValues.md)} ${rem(spacingValues.md)}`,
    lg: `${rem(spacingValues.lg)} ${rem(spacingValues.lg)}`,
    xl: `${rem(spacingValues.xl)} ${rem(spacingValues.xl)}`,
  },
  stack: {
    xs: `0 0 ${rem(spacingValues.xs)} 0`,
    sm: `0 0 ${rem(spacingValues.sm)} 0`,
    md: `0 0 ${rem(spacingValues.md)} 0`,
    lg: `0 0 ${rem(spacingValues.lg)} 0`,
    xl: `0 0 ${rem(spacingValues.xl)} 0`,
  },
};

export const fontWeights = {
  thin: 100,
  xlight: 200,
  light: 300,
  book: 400,
  medium: 600,
  bold: 700,
  black: 900,
};

// Grid related variables
export const columns = {
  xs: 8,
  sm: 8,
  md: 16,
  lg: 32,
  xl: 32,
};
export const gutterWidth = {
  xs: rem(20, false),
  sm: rem(20, false),
  md: rem(20, false),
  lg: rem(20, false),
  xl: rem(20, false),
};
export const paddingWidth = {
  xs: rem(38, false),
  sm: rem(38, false),
  md: rem(42, false),
  lg: rem(26, false),
  xl: rem(26, false),
};
export const container = {
  xs: "full", // 'full' = max-width: 100%
  sm: "full", // 'full' = max-width: 100%
  md: "full", // 'full' = max-width: 100%
  lg: "full", // 'full' = max-width: 100%
  xl: "full", // 'full' = max-width: 100%
};

export const breakpoints = {
  xs: 1,
  sm: rem(480, false),
  md: rem(768, false),
  lg: rem(1152, false),
  xl: rem(1440, false),
};
export const icons = {
  xs: rem(16, true),
  s: rem(24, true),
  m: rem(32, true),
  l: rem(40, true),
};
export const outline = `0 0 3px 2px ${colors.blue}`;

// Mediaquery object for styled-components
// Usage :
// ${mediaquery.sm(css`
//    property: value,
// `)}
export const mediaquery = Object.keys(breakpoints).reduce(
  (acc, label) => ({
    ...acc,
    [label]: (...rules) => css`
      @media (min-width: ${breakpoints[label]}em) {
        ${rules}
      }
    `,
  }),
  {}
);
