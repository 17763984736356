import React, { useContext, useEffect, useState } from "react";

import styled, { css } from "styled-components";

import { mediaquery } from "src/styles/variables";

import getLocalizedSlug from "src/utils/getLocalizedSlug";

import { Link as GatsbyLink } from "gatsby";

import Picture, {
  TYPES as PICTURE_TYPES,
  FITS as PICTURE_FITS,
} from "src/atoms/Picture";

import Settings from "src/stores/Settings";

const HeaderLogo = () => {
  const { headerLogo, locale, hcpLogo, hcpParentSlug } = useContext(Settings);
  const { logoTitle } = useContext(Settings).translations;
  const [currentLogo, setCurrentLogo] = useState(null);

  useEffect(() => {
    setCurrentLogo(
      window.location.pathname.includes(hcpParentSlug) ? hcpLogo : headerLogo
    );
  }, [headerLogo, hcpLogo, hcpParentSlug]);

  return currentLogo ? (
    <Logo
      title={logoTitle}
      to={
        window.location.pathname.includes(hcpParentSlug)
          ? getLocalizedSlug(hcpParentSlug, locale)
          : getLocalizedSlug("/", locale)
      }
    >
      <Picture
        type={PICTURE_TYPES.fixed}
        small={currentLogo}
        fit={PICTURE_FITS.contain}
      />
    </Logo>
  ) : null;
};

const Logo = styled(GatsbyLink)`
  display: block;
  width: 134px;
  height: 34px;
  ${mediaquery.lg(css`
    width: 160px;
    height: 41px;
  `)}
`;

export default HeaderLogo;
